import { Controller } from "@hotwired/stimulus"
import introJs from "intro.js";

// Connects to data-controller="tour"
export default class extends Controller {
  static values = { steps: Array }

  connect() {
    if (window.innerWidth >= 768) {
      this.tour = introJs();
      this.tour.setOptions({
        steps: this.stepsValue.map(step => ({
          element: document.querySelector(step.element),
          intro: step.intro
        })),
        exitOnOverlayClick: false,
        showStepNumbers: false,
        showBullets: true
      });

      this.tour.oncomplete(function() {
        fetch('/users/update_tutorial_status', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({ tutorial_completed: true })
        });
      });

      setTimeout(() => this.startTour(), 5000);
    }
  }

  startTour() {
    const allElementsAvailable = this.stepsValue.every(step => document.querySelector(step.element));
    if (allElementsAvailable) {
      this.tour.start();
    } else {
      console.error("One or more tour steps cannot find their target elements.");
    }
  }
}
