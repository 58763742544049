import { t } from './../i18n';

export function extractConversationDetails(element) {
    const id = parseInt(element.getAttribute('data-conversation-id'), 10); 
    const title = element.getAttribute('data-title');
    const subtitle = element.getAttribute('data-subtitle');
    const status = element.getAttribute('data-status');
    
    return {
        id,
        title,
        subtitle,
        status
    };
}
  
// Function to store chat details in a given target element
export function storeConversationDetails(element, conversationDetails) {
    element.setAttribute('data-conversation-id', conversationDetails.id);
    element.setAttribute('data-title', conversationDetails.title);
    element.setAttribute('data-subtitle', conversationDetails.subtitle  || '');
    element.setAttribute('data-status', conversationDetails.status);
}

export function updateChatDisplayElements(titleElement, subtitleElement, restartElement, conversationDetails) {
    const subtitle = conversationDetails.subtitle || '';
    
    titleElement.textContent = conversationDetails.title;
    subtitleElement.textContent = subtitle;
    subtitleElement.classList.toggle('d-none', !subtitle);
    restartElement.classList.toggle('d-none', conversationDetails.status !== 'automated');
}

export function createSummaryHtml(data) {
    const unreadBadge = data.unreadCount > 0 ? `<span class="badge bg-light-green text-white">${data.unreadCount}</span>` : '';
    const timeStyle = data.unreadCount > 0 ? "small fw-bold text-light-green" : "small text-muted";
    const messageContent = formatMessageContent(data);

    const subtitle = data.conversationDetails.subtitle || '';

    const element = document.createElement('div');
    storeConversationDetails(element, data.conversationDetails);

    return `<div class="chat-summary pb-3" role="button"
                ${element.outerHTML.replace('<div ', '').replace('></div>', '')}
                data-message-id="${data.id}">
                <div class="d-flex justify-content-between align-items-center chat-header mb-2">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="fw-bold mb-1">${data.conversationDetails.title}</span>
                        <span class="small text-gray mb-0">${subtitle}</span>
                    </div>
                    <span class="time-stamp ${timeStyle}">${data.time}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center chat-content">
                    <div class="d-flex align-items-center gap-2 mb-0 text-muted">${messageContent}</div>
                    ${unreadBadge}
                </div>
            </div>`;
}

function escapeHTML(str) {
    var div = document.createElement('div');
    div.appendChild(document.createTextNode(str));
    return div.innerHTML;
}
 
export function generateMessageHTML(data, isActive = false) {
    let messageHtml = '';

    if (data.context) {
        messageHtml += generateMessageContextHTML(data.context);
    }

    messageHtml += generateMessageContentHTML(data, isActive);

    return messageHtml;
}


export function generateMessageContextHTML(content) {
    return `<div class="d-flex justify-content-center align-items-center my-3">
              <div class="bg-white rounded-4 small text-medium-gray px-3 py-1">${content}</div>
            </div>`;
}

export function generateMessageContentHTML(data, isActive) {
    const type = determineMessageType(data);

    let imageHtml = '';
    if (data.imageUrl) {
        imageHtml = `<img src="${data.imageUrl}" alt="Message Image" class="w-100 pt-2" style="max-width: 200px;">`;
    }

    const justifyContentClass = type === 'outgoing' ? 'justify-content-end' : 'justify-content-start';
    const marginClass = type === 'outgoing' ? 'ms-5' : 'me-5';
    const messageTextColorClass = type === 'outgoing' ? 'text-white' : '';
    const timeTextColorClass = type === 'outgoing' ? 'text-very-light-gray' : 'text-gray';
    const statusIconClass = type === 'outgoing' ? getStatusIconClass(data.status) : 'd-none';
    const statusIconHtml = `<span class="message-status-icon ${statusIconClass} flex-shrink-0"></span>`;

    const listingsHtml = generateListingsHtml(data.listings);

    let buttonsHtml = '';
    if (data.replies) {
        buttonsHtml = generateButtons(data.replies, marginClass);
    }

    const messageContent = data.isAutomated ? data.content : escapeHTML(data.content);

    let triggerHtml = '';
    if (data.triggerInfo) {
        triggerHtml = generateTriggerHtml(data.triggerInfo);
    }

    let messageBubbleHtml = '';
    if (data.content || data.imageUrl) {
        messageBubbleHtml = `
            <div class="${type}-message-bubble d-flex flex-column gap-1 ${marginClass}">
                ${imageHtml}
                <div class="d-flex gap-2">
                    <div class="d-flex gap-2 align-items-center pb-2 pt-1" style="min-width: 0;">
                        <span class="w-100 ${messageTextColorClass}" style="white-space: pre-wrap;">${messageContent}</span>
                    </div>
                    <div class="d-flex align-items-center small ${timeTextColorClass} ${data.content ? '' : 'pt-1'} mt-auto ms-auto">
                        ${data.time}
                        ${statusIconHtml}
                    </div>
                </div>
            </div>
        `;
    }

    return `
        <div class="d-flex ${justifyContentClass} mb-2 message-enter ${isActive ? 'message-enter-active' : ''}" data-message-id="${data.id}">
            <div class="d-flex flex-column gap-1">
                ${messageBubbleHtml}
                ${triggerHtml}
                ${listingsHtml}
                ${buttonsHtml}
            </div>
        </div>
    `;
}

function generateTriggerHtml(triggerInfo) {
    // Start building the div element as a string
    let html = '';

    // Check if controller is specified
    if (triggerInfo.controller) {
        // Start the div tag
        html += `<div data-controller="${triggerInfo.controller}"`;

        // Loop through each property in the triggerInfo object
        for (const key in triggerInfo) {
            if (triggerInfo.hasOwnProperty(key) && key !== 'controller') {
                // Construct the attribute name based on the controller name and key
                const attributeName = `data-${triggerInfo.controller}-${key}-value`;
                // Add the attribute to the div string
                html += ` ${attributeName}="${triggerInfo[key]}"`;
            }
        }

        // Close the div tag
        html += '></div>';
    } else {
        console.error('No controller specified in trigger info');
        return null; // Return null if no controller is specified
    }

    return html;
}

function generateListingsHtml(listings) {
    if (!listings || listings.length === 0) {
        return '';
    }

    let listingsHtml = '<div class="overflow-auto hide-scrollbar-md d-flex flex-nowrap gap-2 mt-1">';
    listings.forEach(listing => {
        listingsHtml += `
            <div class="listing-info-light-container rounded-4 flex-shrink-0">
                ${`<img src="${listing.imageUrl}" alt="${escapeHTML(listing.short_name)}" class="listing-thumb rounded-top-4">`}


                <div class="listing-summary-light p-2">
                    <h3 class="fixed-height-light fs-6 mb-1">${escapeHTML(listing.short_name)}</h3>
                    <p class="small text-medium-gray mb-2">${escapeHTML(listing.district_name)}</p>
                    <p class="text-gradient-orange text-center mb-1">${escapeHTML(listing.price)}</p>
                    <a href="${listing.detailsUrl}" data-turbo="false" class="d-block text-center border-top pt-2 text-decoration-none text-blue">${t("view_details")}</a>
                </div>
            </div>
        `;
    });
    listingsHtml += '</div>';

    return listingsHtml;
}

function generateButtons(replies, marginClass) {
    let buttonsHtml = `<div class="d-flex flex-column gap-1 ${marginClass}" data-controller="quick-reply-select">`;
    replies.forEach(reply => {
        const isReplyString = typeof reply === 'string';
        const replyContent = isReplyString ? reply : reply.content;
        const isReply = reply.isReply === undefined ? (isReplyString || !reply.url) : !!reply.isReply;
        const actionAttributes = isReply ? `data-quick-reply-select-target="button" data-action="quick-reply-select#toggleSelect chat#handleReplyClick" data-reply-value="${escapeHTML(replyContent)}"` : '';
        if (!isReplyString && reply.url) {
            buttonsHtml += `<a href="${escapeHTML(reply.url)}" data-turbo="false" class="bg-white border-0 custom-white-button w-100 fw-bold py-2 text-decoration-none rounded-3 d-block text-center" ${actionAttributes}>${replyContent}</a>`;
        } else {
            buttonsHtml += `<button class="bg-white border-0 custom-white-button w-100 fw-bold py-2 rounded-3" ${actionAttributes}>${replyContent}</button>`
        }
    });
    buttonsHtml += '</div>';
    return buttonsHtml;
}

export function updateChatOverview(data, shouldUpdateTimeAndPrepend = true) {
    const chatOverview = document.getElementById('chat-overview');
    if (!chatOverview) {
        return; // Exit the function early if chat-overview element is not found
    }

    const chatList = document.getElementById('chat-list');
    const chatSummaries = chatList.querySelectorAll('div.chat-summary');
    let found = false;

    chatSummaries.forEach(chatSummary => {
        const conversationDetails = extractConversationDetails(chatSummary);
  
        if (data.conversationDetails.id === conversationDetails.id) {
            const messageElement = chatSummary.querySelector('.chat-content div');
            const timeElement = chatSummary.querySelector('span.time-stamp');
            const badgeElement = chatSummary.querySelector('span.badge');

            chatSummary.setAttribute('data-message-id', data.id);

            if (messageElement) {
                messageElement.innerHTML = formatMessageContent(data);
            }
            if (timeElement) {
                if (shouldUpdateTimeAndPrepend) {
                    timeElement.textContent = data.time;
                }
                timeElement.classList.toggle('text-light-green', data.unreadCount > 0);
                timeElement.classList.toggle('text-muted', data.unreadCount <= 0);
            }

            if (data.unreadCount > 0) {
                if (badgeElement) {
                    badgeElement.textContent = data.unreadCount;
                } else {
                    const badgeHtml = `<span class="badge bg-light-green text-white">${data.unreadCount}</span>`;
                    chatSummary.querySelector('.d-flex.chat-content').appendChild(document.createRange().createContextualFragment(badgeHtml));
                }
            } else if (badgeElement) {
                badgeElement.remove(); // Remove badge if no unread messages
            }
            
            found = true;
            if (shouldUpdateTimeAndPrepend) {
                chatList.prepend(chatSummary);
            }
            storeConversationDetails(chatSummary, data.conversationDetails);
        }
    });
  
    if (!found) {
        const newChatHtml = createSummaryHtml(data);
        chatList.insertAdjacentHTML('afterbegin', newChatHtml);  // Prepend the new chat summary
    }

    refreshUnreadCount();
}

function indicateChangeInOverview() {
    // Optional: visually indicate that new messages are available in the overview
}

function formatMessageContent(data) {
    const type = parseInt(document.body.getAttribute('data-user-id'), 10) === data.userId ? 'outgoing' : 'incoming';
    const statusIconClass = type === 'outgoing' ? getStatusIconClass(data.status) : 'd-none';
    const statusIconHtml = `<span class="message-status-icon ${statusIconClass} flex-shrink-0"></span>`;

    if (!!data.imageUrl) {
        // Return the icon and either the content or "Photo" if content is not provided
        return `
            ${statusIconHtml}
            <span class="chat-icon chat-icon-image"></span>
            <span class="chat-summary-content">${escapeHTML(data.content) || "Photo"}</span>
        `;
    } else {
        // Return the text content as is
        return `${statusIconHtml}<span class="chat-summary-content">${escapeHTML(data.content)}</span>`;
    }
}

export function refreshUnreadCount() {
    const chatList = document.getElementById('chat-overview');
    const unreadCount = chatList.querySelectorAll('span.badge').length;

    const chatBubble = document.getElementById('chat-bubble');
    const badgeElement = document.getElementById('unread-chat-counter');

    const newMessageButton = document.getElementById('new-message-button');

    badgeElement.textContent = unreadCount;
    badgeElement.classList.toggle('d-none', unreadCount === 0);
    chatBubble.classList.toggle('border', unreadCount === 0);
    chatBubble.classList.toggle('chat-button-unread', unreadCount > 0);
    if (newMessageButton) {
        newMessageButton.classList.toggle('d-none', unreadCount === 0);
    }
}

export function updateMessageStatus(element, id, status) {
    if (element) {
        const statusElement = element.querySelector(`[data-message-id="${id}"] .message-status-icon`);
        if (statusElement) {
            statusElement.className = `message-status-icon ${getStatusIconClass(status)}`;
        }
    }
}

function getStatusIconClass(status) {
    const statusClassMap = {
        sent: "chat-icon chat-icon-single-tick",
        delivered: "chat-icon chat-icon-double-tick",
        read: "chat-icon chat-icon-double-tick-blue",
        failed: "chat-icon chat-icon-exclamation"
    };
    return statusClassMap[status] || '';
}

function determineMessageType(data) {
    const userIdAttribute = document.body.getAttribute('data-user-id');
    const userId = parseInt(userIdAttribute, 10);
    const isOutgoing = (isNaN(userId) && data.userId) || userId === data.userId;
    return isOutgoing ? 'outgoing' : 'incoming';
}

export function getLocalStorageItem(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        // No item found for the given key
        return null;
    }
    const item = JSON.parse(itemStr);
    if (item.expiry) {
        const now = new Date();
        if (now.getTime() > item.expiry) {
            // Expiry has passed, clear the item and return null
            localStorage.removeItem(key);
            return null;
        }
    }
    return item.value;
}

export function setLocalStorageItem(key, value, expiryHours = null) {
    const item = { value: value };
    if (expiryHours) {
        const now = new Date();
        item.expiry = now.getTime() + (expiryHours * 60 * 60 * 1000);
    }
    localStorage.setItem(key, JSON.stringify(item));
}

export function calculateTypingDelay() {
    const typingStartTime = parseInt(getLocalStorageItem('typing_start_time'), 10);
    const now = new Date().getTime();
    const elapsed = now - typingStartTime;
    const minimumDisplayTime = 1000; // Minimum time to display typing animation in milliseconds
    return Math.max(0, minimumDisplayTime - elapsed);
  }