import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slider"
export default class extends Controller {
  static values = {
    start: Array,
    min: Number,
    max: Number,
    step: { type: Number, default: 1 },
    isCurrency: Boolean,
    range: Object,
    titleId: String,
    suffix: String
  }

  static targets = ["slider", "minInput", "maxInput"]

  async connect() {
    const noUiSlider = await import('nouislider/dist/nouislider.mjs');
    const effectiveRange = this.hasRangeValue ? this.rangeValue : { 'min': this.minValue, 'max': this.maxValue };

    noUiSlider.create(this.sliderTarget, {
      start: this.startValue,
      connect: true,
      range: effectiveRange,
      step: this.stepValue,
      format: this.isCurrencyValue ? this.currencyFormat() : this.defaultFormat()
    });

    this.sliderTarget.noUiSlider.on('update', (rawValues, handle, unencoded) => {
      this.updateValues(rawValues, unencoded);
    });
  }

  updateValues(rawValues, unencoded) {
    this.minInputTarget.value = unencoded[0];
    this.maxInputTarget.value = unencoded[1];
    const titleElement = document.getElementById(this.titleIdValue);

    if (titleElement) {
      let suffix = this.hasSuffixValue ? ` ${this.suffixValue}` : '';
      if (unencoded[0] !== 1 || unencoded[1] !== 1) {
        if (suffix.match(/[A-Za-z]$/) && !suffix.endsWith('s')) {
          suffix += 's';
        }
      }

      titleElement.textContent = (rawValues[0] === rawValues[1] ? rawValues[0] : `${rawValues[0]} - ${rawValues[1]}`) + suffix;
    }
  }

  disconnect() {
    if (this.sliderTarget.noUiSlider) {
      this.sliderTarget.noUiSlider.destroy();
    }
  }

  defaultFormat() {
    return {
      to: function(value) {
        return Math.round(value).toString();
      },
      from: function(value) {
        return parseFloat(value);
      }
    };
  }

  currencyFormat() {
    return {
      to: function(value) {
        return `$${(value / 1000).toFixed(0)}k`;
      },
      from: function(value) {
        return parseFloat(value.replace(/\$\s?|k/g, '')) * 1000;
      }
    };
  }
}
